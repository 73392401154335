<template>
  <div id="bug-report-histori" data-app>
    
    <!--begin::Notice-->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg svg-icon-primary">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Alerts</b> Provide contextual feedback messages for typical user
        actions with the handful of available and flexible alert messages.
        <a
          class="font-weight-bold"
          href="https://bootstrap-vue.js.org/docs/components/alert"
          target="_blank"
        >
          See documentation.
        </a>
      </div>
    </b-alert>
    <!--end::Notice-->

    <div class="card card-custom">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Tiket Bug</span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">List temuan bug pada aplikasi yang telah diajukan</span>
          </h3>
          <div class="card-toolbar">
              <a v-b-tooltip.hover  @click="$router.push({ name: 'bug-report-add' })" title="Add New" class="btn btn-primary font-weight-bolder font-size-sm mr-2">
                <span class="svg-icon svg-icon-md svg-icon-white">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/File.svg" />
                    <!--end::Svg Icon-->
                </span>
                <span class="d-none d-sm-inline">Tambah</span>                
              </a>
          </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div class="table-responsive">
              <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                      <tr class="text-uppercase">
                          <th class="pl-7">
                              <span class="text-primary">Subjek</span>
                          </th>
                          <th class="pl-7 d-none d-md-table-cell">
                              <span>Status</span>
                          </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                      <tr v-for="(val, key) in items" :key="key">
                          <td class="pl-0 py-5">
                              <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 flex-shrink-0 mr-4">
                                      <div class="symbol-label">
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                      </div>
                                  </div>
                                  <div>
                                      <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ val.subjek }}</a>
                                      <span class="text-muted font-weight-bold d-block font-size-sm">{{ val.tanggal | timeAgo }}</span>
                                  </div>
                              </div>
                          </td>
                          <td class="d-none d-md-table-cell">
                            <div>
                                <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                                <span class="text-muted font-weight-bold d-block font-size-sm">{{ val.prioritas | title }}</span>
                            </div>
                          </td>
                          <td class="text-right pr-0">
                            <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="d-none d-sm-inline">Action</span>
                                </template>                                        
                                <b-dropdown-item @click="$router.push(`/bug-report/histori/${val._id}`)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Book-open.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Detail</span>
                                </b-dropdown-item>
                            </b-dropdown>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                      
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Body-->
      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import BugReportService from "@/core/services/api/other/bugreport.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "bug-report-histori", 
  components: {
    ContentLoader,
  },  
  data() {
    return {
      show: true,
      isLoading: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 8,
      items: [],
    }
  },
  methods: {
    changePage(val){
      if(this.changePage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
    },
    getData (page = 1, perPage = this.perPage) {
      BugReportService.getBugReport({page, per_page: perPage})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isLoading = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            this.isLoading = false
        })
        .catch((err) => {
          this.resetTable()
          this.isLoading = false
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bug Report" }]);
    this.getData()
  },
};
</script>